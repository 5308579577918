import axios from 'axios'

// Exercicios
export function startQuiz (data) {
  return axios.post('/v1/quiz/start', data)
}

export function nextQuestion () {
  return axios.get('/v1/quiz/next-question')
}

export function submitAnswer (data) {
  return axios.post('/v1/quiz/submit-answer', data)
}

export function skipQuestion (data) {
  return axios.post('/v1/quiz/skip-question', data)
}

export default {
  startQuiz,
  nextQuestion,
  submitAnswer,
  skipQuestion
}
