import axios from 'axios'

// Professores
export function getCoordinators () {
  return axios.get('/v1/coordinators')
}

export function getCoordinator (id) {
  return axios.get('/v1/coordinators/' + id)
}

export function newCoordinator (data) {
  return axios.post('/v1/coordinators', data)
}

export function disableCoordinator (roleId) {
  return axios.post('/v1/role/' + roleId + '/disable')
}

export function enableCoordinator (roleId) {
  return axios.post('/v1/role/' + roleId + '/enable')
}

export default {
  getCoordinators,
  getCoordinator,
  newCoordinator,
  disableCoordinator,
  enableCoordinator
}
