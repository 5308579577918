import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueMask from 'v-mask'
import './plugins/api.js'
import './plugins/auth.js'
import './plugins/sentry.js'
import './plugins/bootstrap-vue.js'

import AuButton from '@/components/AudeUi/AuButton/AuButton.vue'
import AuButtonIcon from '@/components/AudeUi/AuButtonIcon/AuButtonIcon.vue'

import './styles/main.scss'
import 'katex/dist/katex.min.css'

import router from './router'

Vue.component('AuButton', AuButton)
Vue.component('AuButtonIcon', AuButtonIcon)
Vue.config.productionTip = false

function setLayoutForNextPage (to) {
  let layout = to.matched[0]?.components.default.layout

  if (!layout) {
    layout = 'Default'
  }

  store.commit('setLayout', layout)
}

router.afterEach(setLayoutForNextPage)
Vue.use(VueMask)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

setLayoutForNextPage(router.currentRoute)
