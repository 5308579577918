import axios from 'axios'

// Exames, provas

export function listExams (bool, page, schoolCourseId) {
  let url = ''
  url = schoolCourseId ? '&school_course_id=' + schoolCourseId : ''
  return axios.get('/v1/institution-exams?is_published=' + bool + '&page=' + page + url)
}

export function listRoleExamsBySchoolCourse (bool, roleid, schoolCourseId, page) {
  return axios.get('/v1/role-institution-exams?is_published=' + bool + '&answering_role_id=' + roleid + '&school_course_id=' + schoolCourseId + '&page=' + page)
}
export function listRoleExamsOpen (bool, roleid, schoolCourseId, page) {
  return axios.get('/v1/role-institution-exams/open?is_published=' + bool + '&answering_role_id=' + roleid + '&school_course_id=' + schoolCourseId + '&page=' + page)
}
export function listRoleExamsEnded (bool, roleid, schoolCourseId, page) {
  return axios.get('/v1/role-institution-exams/ended?is_published=' + bool + '&answering_role_id=' + roleid + '&school_course_id=' + schoolCourseId + '&page=' + page)
}

export function getExam (id) {
  return axios.get('/v1/institution-exams/' + id + '?with=institutionExamQuestionGroups,schoolCourses')
}

export function newExam (data) {
  return axios.post('/v1/institution-exams', data)
}

export function updateExam (id, data) {
  return axios.put('/v1/institution-exams/' + id, data)
}

export function deleteExam (id) {
  return axios.delete('/v1/institution-exams/' + id)
}
export function unPublishExam (id) {
  return axios.post('/v1/institution-exams/' + id + '/unpublish')
}

export function questionPerTopicID (source, topic) {
  let url = '/v1/questions?source=' + source
  if (topic) {
    url += '&topic_id=' + topic
  }
  url += '&with=classifiedQuestions,questionBoard,topics'
  return axios.get(url)
  // return axios.get('/v1/topics/' + id + '?with=questions,questions.questionBoard')
}

export function getQuestions (id) {
  return axios.get('/v1/institution-exam-question-groups/' + id + '/question-topic?with=questionBoard')
}

export function getQuestionsFull (id) {
  return axios.get('/v1/institution-exam-question-groups/' + id + '/question-topic?with=question.questionBoard')
}

export function getQuestion (id) {
  return axios.get('/v1/questions/' + id + '?with=questionBoard')
}

export function addQuestions (id, data) {
  return axios.post('/v1/institution-exam-question-groups/' + id + '/question-topic', data)
}

export function deleteExamQuestion (id) {
  return axios.delete('/v1/institution-exam-questions/' + id)
}

export function addSchoolcourseExam (id, data) {
  return axios.post('/v1/institution-exams/' + id + '/school-courses', data)
}

export function deleteSchoolcourseExam (id) {
  return axios.delete('/v1/institution-exam-school-course/' + id)
}

export function publishExam (id) {
  return axios.post('/v1/institution-exams/' + id + '/publish')
}

export function startExam (id) {
  return axios.post('/v1/institution-exams/' + id + '/institution-exam-answer-sheets')
}

export function setAnswer (id, data) {
  return axios.post('/v1/institution-exam-answer-sheet/' + id + '/institution-exam-answers', data)
}

export function deleteAnswerSheet (id) {
  return axios.delete('/v1/institution-exam-answer-sheet/' + id)
}

export function finishExam (id) {
  return axios.post('/v1/institution-exam-answer-sheet/' + id + '/finish')
}

export function getExamResults (id, schoolCourseId) {
  return axios.get('/v1/institution-exams/' + id + '/results?school_course_id=' + schoolCourseId)
}

export function getRoleExam (id, roleid) {
  return axios.get('/v1/role-institution-exams/' + id + '?answering_role_id=' + roleid)
}

export function getCorrection (id) {
  return axios.get('/v1/institution-exam-answer-sheet/' + id + '?with=institutionExamAnswers.institutionExamQuestion')
}

export default {
  newExam,
  getExam,
  listExams,
  updateExam,
  deleteExam,
  unPublishExam,
  addSchoolcourseExam,
  deleteSchoolcourseExam,
  getQuestions,
  questionPerTopicID,
  addQuestions,
  getQuestion,
  deleteExamQuestion,
  publishExam,
  listRoleExamsBySchoolCourse,
  startExam,
  setAnswer,
  deleteAnswerSheet,
  finishExam,
  getExamResults,
  getCorrection,
  getRoleExam,
  getQuestionsFull,
  listRoleExamsOpen,
  listRoleExamsEnded
}
