import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: '',
    linkMaterial: '',
    schoolCourse: null,
    itemToLearn: null,
    subjectLearn: null,
    topicLearn: null,
    alertFeedBack: {
      status: false,
      type: '',
      titulo: '',
      variant: '',
      msg: ''
    },
    sizeMobile: null,
    filterQuestion: null,
    sourceQuestions: null,
    contract: null,
    teachingSystems: null,
    role: null,
    avAude: null
  },

  // INFO: Mutations são verbosas, e o Pinia[1] já desistiu delas.
  // Porém, no Vuex elas ainda são obrigatórias.
  // [1] https://pinia.vuejs.org/introduction.html#comparison-with-vuex-3-x-4-x
  //
  // TODO: Usar arrow functions
  mutations: {
    setLayout (state, layout) {
      state.layout = layout
    },
    setLinkMaterial (state, address) {
      state.linkMaterial = address
    },
    setSchoolCourse (state, schoolCourse) {
      state.schoolCourse = schoolCourse
    },
    setAlertFeedBack (state, alertFeedBack) {
      state.alertFeedBack = alertFeedBack
    },
    setSizeMobile: (state, size) => { state.sizeMobile = size },
    setSourceQuestions: (state, source) => { state.sourceQuestions = source },
    setFilterQuestion: (state, filter) => { state.filterQuestion = filter },
    setItemToLearn: (state, learn) => { state.itemToLearn = learn },
    setSubjectLearn: (state, subject) => { state.subjectLearn = subject },
    setTopicLearn: (state, topic) => { state.topicLearn = topic },
    setContract: (state, contract) => { state.contract = contract },
    setTeachingSystems: (state, teachingSystems) => { state.teachingSystems = teachingSystems },
    setRole: (state, role) => { state.role = role },
    setAvAude: (state, avAude) => { state.avAude = avAude }
  },

  actions: {
  },

  modules: {
  }
})
