import axios from 'axios'

// Banco de questões
export function newQuestion (data) {
  return axios.post('/v1/questions', data)
}

export function updateQuestion (id, data) {
  return axios.put('/v1/questions/' + id, data)
}

export function uploadImage (id, size, data) {
  return axios.post('/v1/questions/' + id + '/question-images?size_template=' + size, data)
}

export function listQuestions (page, source, topic) {
  let url = '/v1/questions?paginate=true&page=' + page + '&source=' + source
  if (topic) {
    url += '&topic_id=' + topic
  }
  url += '&with=questionBoard,topics'
  return axios.get(url)
}

export function getQuestionToEdit (id) {
  return axios.get('/v1/questions/' + id + '?with=questionBoard,topics')
}

export function deleteQuestion (id) {
  return axios.delete('/v1/questions/' + id)
}

export default {
  newQuestion,
  updateQuestion,
  uploadImage,
  listQuestions,
  getQuestionToEdit,
  deleteQuestion
}
