import webAuth from '@/auth/webAuth'
import tokenAuth from '@/auth/tokenAuth'

let auth = null

if (process.env.VUE_APP_PLATFORM === 'mobile') {
  auth = tokenAuth
} else {
  auth = webAuth
}

export default auth
