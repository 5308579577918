
import axios from '@/axios'

// Autenticação
export function getCookie () {
  return axios.get('/csrf-cookie')
}

export async function lookUp (data) {
  return await axios.post('/v1/auth/lookup', data)
}

export function login (data) {
  data.client_id = 'pvAo42aw4h3fvEx3BxWUs7D5p3azTuiHeXY23oak'
  return axios.post('/v1/auth/login', data)
}

export async function logout () {
  localStorage.clear()
  return axios.get('/v1/auth/logout')
}

export async function tokenLogin (data) {
  return axios.post('/v1/auth/sanctum/token', data)
}

export async function tokenLogout () {
  localStorage.clear()
  return axios.post('/v1/auth/sanctum/token-logout')
}

export function enableRole (role) {
  axios.defaults.headers.common['X-Aude-Role-Id'] = role
}

export function disableRole () {
  delete axios.defaults.headers.common['X-Aude-Role-Id']
}

export function checkPassword (data) {
  return axios.post('/v1/auth/check-password', data)
}

export default {
  getCookie,
  lookUp,
  login,
  logout,
  tokenLogin,
  tokenLogout,
  enableRole,
  disableRole,
  checkPassword
}
