import { types as users } from '@/enums/roleType'
const HomeInstitution = () => import('@/views/portal/institution/Home-institution.vue')
const Coordinators = () => import('@/views/portal/institution/Coordinators.vue')
const Teachers = () => import('@/views/portal/institution/Teachers.vue')
const Students = () => import('@/views/portal/institution/Students.vue')
const SchoolCourses = () => import('@/views/portal/institution/SchoolCourses.vue')
const Articles = () => import('@/views/portal/institution/Articles.vue')
const ArticlesID = () => import('@/views/portal/institution/Article_id.vue')
const ExamList = () => import('@/views/portal/institution/Exam/ExamList.vue')
const ExamID = () => import('@/views/portal/institution/Exam/Exam_id.vue')
const ExamPrint = () => import('@/views/portal/institution/Exam/Exam_print.vue')
const Ajuda = () => import('@/views/portal/institution/Ajuda.vue')
const questionBank = () => import('@/views/portal/institution/questionBank/Question-bank.vue')
const QuestinoEdit = () => import('@/views/portal/institution/questionBank/Question-edit.vue')

export default [
  {
    path: '/escola',
    name: 'home-institution',
    component: HomeInstitution,
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/coordenadores',
    name: 'coordinators',
    component: Coordinators,
    meta: { permissions: [users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/professores',
    name: 'teachers',
    component: Teachers,
    meta: { permissions: [users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/turmas',
    name: 'institution.school-courses',
    component: SchoolCourses,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/alunos',
    name: 'students',
    component: Students,
    meta: { permissions: [users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/artigos',
    name: 'institution.articles',
    component: Articles,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/artigo/:id',
    name: 'institution.article-id',
    component: ArticlesID,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/avaliacoes/publicadas',
    name: 'institution.exams.published',
    props: { published: true },
    component: ExamList,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/avaliacoes/rascunho',
    name: 'institution.exams.unpublished',
    props: { published: false },
    component: ExamList,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/avaliacao/:id',
    name: 'institution.exam-id',
    component: ExamID,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/imprimir-avaliacao/:examid',
    name: 'institution.exam.print',
    component: ExamPrint,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/ajuda',
    name: 'ajuda',
    component: Ajuda,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/banco-de-questoes',
    name: 'institution.question.list',
    component: questionBank,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/banco-de-questoes/editor/:id',
    name: 'institution.question.edit',
    component: QuestinoEdit,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/escola/banco-de-questoes/criar-questao',
    name: 'institution.question.create',
    component: QuestinoEdit,
    meta: { permissions: [users.Teacher, users.Maintainer, users.Coordinator] }
  }
]
