<template>
  <div class="text">
    <div class="ml-2 mt-6">
      Nova senha
    </div>
    <b-form-group
      invalid-feedback="Minimo de 8 caracteres"
    >
      <b-form-input
        id="newpassword"
        v-model="newPassword"
        autocomplete="password"
        class="mb-2"
        type="password"
        :disabled="disableForm"
        :readonly="loading"
        :state="rulesPassword"
      />
    </b-form-group>
    <div class="ml-2">
      Confirme a senha
    </div>
    <b-form-group
      invalid-feedback="Confirmação de senha não confere"
    >
      <b-form-input
        id="confirmPassword"
        v-model="confirmPass"
        autocomplete="password"
        type="password"
        :disabled="!rulesPassword || disableForm"
        :readonly="loading"
        :state="rulesConfirmPassword"
      />
    </b-form-group>
    <AuButton
      class="mb-3 btn-aude-primary btn-aude-block"
      label="Alterar senha"
      :disabled="rulesBtn"
      @click="emitNewPassword()"
    />
  </div>
</template>
<script>

export default {

  props: {
    disableForm: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  data () {
    return {
      newPassword: '',
      confirmPass: ''
    }
  },

  computed: {
    rulesPassword () {
      if (this.newPassword.length < 8 && this.newPassword.length > 0) {
        return false
      }
      if (this.newPassword.length > 7) {
        return true
      }
      return null
    },
    rulesConfirmPassword () {
      if (this.confirmPass.length > 0 && this.newPassword !== this.confirmPass) {
        return false
      }
      if (this.confirmPass.length > 0 && this.newPassword === this.confirmPass) {
        return true
      }
      return null
    },
    rulesBtn () {
      if (this.loading) {
        return false
      }
      if (this.rulesConfirmPassword && this.rulesPassword) {
        return false
      }
      return true
    }
  },

  methods: {

    emitNewPassword () {
      this.$emit('newpass', this.newPassword)
    }
  }
}
</script>
