<template>
  <div style="text-align: center;">
    <img
      class="mt-20 mb-10"
      src="@/assets/email_enviado.svg"
      width="170px"
    >
    <br>
    {{ msg }}

    <AuButton
      class="mt-4 btn-aude-primary btn-aude-block"
      label="OK 2"
      @click="$emit('toLogin')"
    />
  </div>
</template>

<script>

export default {
  components: {
  },

  props: {
    msg: { type: String, required: true }
  },

  data: () => ({
  }),

  methods: {
  }
}
</script>
