
import { types as users } from '@/enums/roleType'
export default [
  {
    path: '/conteudos',
    name: 'pre-learn',
    component: () => import('@/views/portal/learn/PreLearn.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/itinerarios',
    name: 'learn.itinerarios',
    component: () => import('@/views/portal/learn/LearnItinerarios.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/itinerarios/topicos',
    name: 'learn.topics.itinerarios',
    component: () => import('@/views/portal/learn/LearnTopicsItinerarios.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/itinerarios/material-didatico',
    name: 'learn.subject-textbooks.itinerarios',
    component: () => import('@/views/portal/schoolcourse/SubjectTextBookItinerarios.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
    props: true
  },
  {
    path: '/conteudos/aprender',
    name: 'home-learn',
    component: () => import('@/views/portal/learn/Learn.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/topicos',
    name: 'learn.topics',
    component: () => import('@/views/portal/learn/LearnTopics.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/topico/:id',
    name: 'learn.topics.topic',
    component: () => import('@/views/portal/learn/LearnTopic.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/material-didatico',
    name: 'learn.subject-textbooks',
    component: () => import('@/views/portal/schoolcourse/SubjectTextBook.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
    props: true
  },
  {
    path: '/conteudos/aprender/material/:hashid',
    name: 'subject-textbook-display',
    component: () => import('@/views/portal/SubjectTextBookDisplay.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/atividades-infantis',
    name: 'atividades-infantis',
    component: () => import('@/views/portal/atividades-infantis/Atividades.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/conteudos/aprender/atividades-infantis/:id',
    name: 'atividade-infantil-id',
    component: () => import('@/views/portal/atividades-infantis/Atividade.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  }
  // {
  //   path: '/conteudos/aprender/exercicio/:id',
  //   name: 'exercise',
  //   component: () => import('@/views/portal/Exercise_id_old.vue'),
  //   meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  // }
]
