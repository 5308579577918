import axios from 'axios'

// av360

export function getRoleDiagnostics (roleId) {
  return axios.get('/v1/role/' + roleId + '/diagnostics')
}

export function getDiagnostic (diagnosticID) {
  return axios.get('/v1/diagnostics/' + diagnosticID)
}

export function registerAcess () {
  return axios.post('/v1/role-selections')
}

export function registerAccessesMediaContent (data) {
  return axios.post('/v1/classified-media-content-accesses/create-from-ids', data)
}

export function navigations (data) {
  return axios.post('/v1/navigations', data)
}

export default {
  getRoleDiagnostics,
  getDiagnostic,
  registerAcess,
  registerAccessesMediaContent,
  navigations
}
