import axios from 'axios'

// Material Text Books

export function getVolumes (id) {
  return axios.get('/v1/grade-levels/' + id + '/volumes')
}

export function getTextBooks (id, system, year, yearAddtional, volumeid) {
  return axios.get('/v1/subject-textbooks?grade_level_id=' + id + '&teaching_system_id=' + system + '&publishing_year=' + year + '&publishing_year_additional=' + yearAddtional + '&volume_id=' + volumeid)
}
export function getTextBookswithoutVolume (id, system, year, yearAddtional) {
  return axios.get('/v1/subject-textbooks?grade_level_id=' + id + '&teaching_system_id=' + system + '&publishing_year=' + year + '&publishing_year_additional=' + yearAddtional)
}
export function getTextBooksPerSubject (id, system, year, yearAddtional, volumeid, subjectid) {
  return axios.get('/v1/subject-textbooks?grade_level_id=' + id + '&teaching_system_id=' + system + '&publishing_year=' + year + '&publishing_year_additional=' + yearAddtional + '&volume_id=' + volumeid + '&subject_id=' + subjectid)
}

export function getTextBook (hashid) {
  return axios.get('/v1/subject-textbooks/hashid/' + hashid)
}

export default {
  getVolumes,
  getTextBooks,
  getTextBook,
  getTextBookswithoutVolume,
  getTextBooksPerSubject
}
