import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '@/plugins/api.js'
import utils from '@/api/utils.js'
import { types as users } from '@/enums/roleType'

// Groups
import institution from './groups/institution'
import schoolcourse from './groups/schoolcourse'
import learn from './groups/learn'

// geral
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Enrollment from '../views/Enrollment.vue'
import Verify from '@/views/Verify.vue'
import PasswordRecovery from '@/views/PasswordRecovery.vue'
// portal
import Error404 from '../views/Error404.vue'
import { App } from '@capacitor/app'

// const Diagnostics = () => import('@/views/portal/diagnostics/diagnostics.vue')
const Diagnostics = () => import('@/views/portal/diagnostics/diags.vue')
const AudeExam = () => import('@/views/portal/AudeExam/AudeExam.vue')
const Profile = () => import('@/views/portal/Profile.vue')
const DeleteAccount = () => import('@/views/portal/DeleteAccount.vue')

Vue.use(VueRouter)

const routes = [
  ...institution,
  ...schoolcourse,
  ...learn,

  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/cadastrar',
    name: 'Register',
    component: Register
  },
  {
    path: '/matriculas',
    name: 'Enrollment',
    component: Enrollment
  },
  {
    path: '/diagnostico',
    name: 'Diagnostics',
    component: Diagnostics,
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/avaliacao-aude/:id',
    name: 'AudeExam',
    component: AudeExam,
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/perfil',
    name: 'profile',
    component: Profile,
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/perfil/excluir-conta',
    name: 'Delete.account',
    component: DeleteAccount,
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
  },
  {
    path: '/auth/verify',
    name: 'verify',
    component: Verify,
    meta: { norequiresAuth: true }
  },
  {
    path: '/auth/redefinir-senha',
    name: 'password-recovery',
    component: PasswordRecovery,
    meta: { norequiresAuth: true }
  },
  { path: '*', component: Error404, meta: { norequiresAuth: true, permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] } }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.norequiresAuth)) {
    next()
  } else if ((to.name !== 'Login' && to.name !== 'Register') && api.checkLogin() === false) next({ name: 'Login' })
  else next()
})

router.afterEach((to, from) => {
  if (to.name !== 'Enrollment' && to.name !== 'updatepass' && to.name !== 'updateemail' && to.name !== 'verify' && to.name !== 'Login') {
    if (!utils.checkPermission(to)) {
      const home = utils.getHome()
      router.push({ name: home })
    }
  }
  const role = utils.getRole()
  const user = utils.getUser()
  const data = {
    name: to.name,
    from_name: from.name,
    full_path: to.fullPath,
    params: to.params,
    query: to.query,
    role_id: null,
    user_id: null
  }
  if (role) {
    data.role_id = role.id
  }
  if (user) {
    data.user_id = user.id
  }
  api.navigations(data).catch(error => {
    // 419 é um erro de sessão expirada, então capturamos e não fazemos nada
    // para evitar que o evento seja registrado pelo Sentry.
    if (error.response && error.response.status === 419) {
      return
    }

    throw error
  })
})

App.addListener('appUrlOpen', function (event) {
  const url = new URL(event.url)
  const slug = url.pathname + url.search
  if (slug) {
    router.push({
      path: slug
    })
  }
})

export default router
