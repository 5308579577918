<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center vh-100 align-items-center">
      <div
        class="spinner-border"
        role="status"
      >
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
    <b-modal
      id="modal-FeedBack"
      v-model="modalFeedBack"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer
      centered
      no-stacking
      size="md"
    >
      <template v-if="success">
        <p class="mt-10 mb-10 ml-5 mr-5 text-center">
          Seu e-mail foi confirmado com sucesso
        </p>
        <AuButton
          class="btn-aude-primary btn-aude-block"
          label="OK"
          @click="goToHome"
        />
      </template>
      <template v-else>
        <p class="mt-10 mb-10 ml-5 mr-5 text-center">
          Ocorreu algum erro, tente novamente mais tarde
        </p>
        <AuButton
          class="btn-aude-primary"
          label="Entendi"
          @click="goToHome"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>

export default {
  layout: 'Auth',

  data () {
    return {
      url: '',
      modalFeedBack: false,
      success: false
    }
  },

  created () {
    this.fetchHomeContent()
  },

  methods: {
    async fetchHomeContent () {
      this.url = this.$route.query.verification_url
      try {
        await this.$api.getCookie()
        await this.$api.verify(this.url)
        await this.$api.me()
        this.modalFeedBack = true
        this.success = true
      } catch (e) {
        this.modalFeedBack = true
        this.success = false
      }
    },

    goToHome () {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
