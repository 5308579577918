<template>
  <div class="default-layout">
    <ConfirmEmail />
    <NavBar />

    <div class="pb-20 pb-md-5">
      <slot />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/portal/NavBar.vue'
import ConfirmEmail from '@/components/app/ConfirmEmail.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    NavBar,
    ConfirmEmail
  },

  data: () => ({
    role: {},
    permission: true,
    confirmeEmail: false
  }),

  created () {
    this.fetchHomeContent()
  },

  methods: {
    fetchHomeContent () {
      this.role = utils.getRole()

      // FIXME: O método getRole deveria retornar null em caso de
      // não haver role, simplificando a verificação abaixo para:
      // if (!this.role)
      if (utils.size(this.role) === 0) {
        this.$router.push({ name: 'Enrollment' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout {
  min-height: 100vh;
  background-color: #F3F4F6;
}
</style>
