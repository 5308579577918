import axios from 'axios'

// Usuários
export function getCookie () {
  return axios.get('/csrf-cookie')
}

export async function me () {
  try {
    const response = await axios.get('/v1/auth/me')

    // FIXME: Api não deve ter essa responsabilidade
    localStorage.setItem('user', JSON.stringify(response.data.data))

    return response.data.data
  } catch (e) {
    throw (e.response)
  }
}

export async function newUser (data) {
  return axios.post('/v1/auth/register', data)
}

export function forgotPass (data) {
  return axios.post('/v1/auth/password/email', data)
}

export function updateMe (data) {
  return axios.put('/v1/me', data)
}

export function acceptTerms () {
  return axios.post('/v1/me/accept-terms')
}

export function sendAvatar (data) {
  return axios.post('/v1/me/avatar', data)
}

export function deleteAvatar () {
  return axios.delete('/v1/me/delete-avatar')
}

export function updateTempPassword (data) {
  return axios.post('/v1/me/update-temporary-password', data)
}

export function changeEmail (data) {
  return axios.post('/v1/auth/me/update-email', data)
}

// TODO: Essa função não é necessária.
// Chamar o axios diretamente no componente.
export function verify (url) {
  return axios.post(url)
}

// TODO: Essa função não é necessária.
// Chamar o axios diretamente no componente.
export function newPass (url, data) {
  return axios.post(url, data)
}

export async function reSend (data) {
  await this.getCookie()
  return axios.post('/v1/auth/email/resend', data)
}
// matriculas
export async function roles () {
  const response = await axios.get('/v1/me/roles?with=institution,schoolCourses')
  return response.data.data
}

export function activateRole (role) { // matricula em uso
  return axios.post('/v1/me/roles/' + role + '/activate')
}

export function deleteMe (data) {
  return axios.put('/v1/me/delete', data)
}

export function newFakeRole (data) { // assinante fake
  return axios.post('/v1/dev/create-fake-subscription', data)
}

export function defaulting () { // recebe mensagem de inadimplencia
  return axios.get('/v1/defaulting-insitution-message')
}

// turmas

export function getMySchoolCourse (id) {
  return axios.get('/v1/school-courses/' + id + '?with=schoolCourseSubjects,teachers.user,students.user')
}

export default {
  me,
  newUser,
  forgotPass,
  updateMe,
  deleteMe,
  acceptTerms,
  sendAvatar,
  deleteAvatar,
  updateTempPassword,
  changeEmail,
  verify,
  newPass,
  reSend,
  roles,
  defaulting,
  activateRole,
  newFakeRole,
  getMySchoolCourse
}
