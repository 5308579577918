import axios from 'axios'

// Alunos
export function getStudents () {
  return axios.get('/v1/students')
}

export function getStudent (id) {
  return axios.get('/v1/students/' + id + '?with=schoolCourses')
}

export function newStudent (data) {
  return axios.post('/v1/students', data)
}

export function disableStudent (roleId) {
  return axios.post('/v1/role/' + roleId + '/disable')
}

export function enableStudent (roleId) {
  return axios.post('/v1/role/' + roleId + '/enable')
}

export function updateRole (roleId, data) {
  return axios.put('/v1/roles/' + roleId, data)
}

export default {
  getStudents,
  getStudent,
  newStudent,
  disableStudent,
  enableStudent,
  updateRole
}
