import Vue from 'vue'
import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
// import router from '../router'

Sentry.init({
  Vue,
  dsn: 'https://de7034c57fbbc0fbc7278b4ecff92534@o4505938340413440.ingest.sentry.io/4505938345328640',
  // integrations: [
  //   new BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: [process.env.VUE_APP_API_HOST, /^\//]
  //   })
  // ],
  ignoreErrors: ['NavigationDuplicated'],
  environment: `${process.env.VUE_APP_TARGET_ENV}`,
  release: `portal@1.31-${process.env.VUE_APP_PLATFORM}`,
  initialScope: {
    tags: {
      version: '1.31',
      platform: process.env.VUE_APP_PLATFORM
    }
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production

  // TODO - Verificar se devemos habilitar o sessionTracking e tracesSampleRate quando migrar para o GlitchTip
  autoSessionTracking: false,
  tracesSampleRate: 0
})
