<template>
  <b-modal
    id="modal-change-temporary-password"
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    no-stacking
    v-on="$listeners"
  >
    <b-overlay
      :show="loading"
    >
      Para sua segurança, defina uma nova senha para sua conta:
      <UpdatePassword
        @newpass="setNewPassword($event)"
      />
    </b-overlay>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'
import UpdatePassword from '@/components/UpdatePassword.vue'
export default {
  components: {
    UpdatePassword
  },
  model: BModal.options.model,

  data: () => ({
    modalUpdatePass: false,
    loading: false
  }),

  methods: {
    async setNewPassword (newpass) {
      const data = {
        new_password: newpass
      }
      this.loading = true
      try {
        await this.$api.updateTempPassword(data)
        await this.$api.me()
        this.$emit('close')
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$emit('error', e.response)
      }
    }
  }
}
</script>
