<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <div
      class="input-group"
      :class="{ 'is-invalid': error }"
    >
      <input
        :id="id"
        class="form-control form-control-lg"
        :class="{ 'is-invalid': error }"
        v-bind="$attrs"
        :value="value"
        :type="reveal ? 'text' : 'password'"
        v-on="listeners"
      >
      <div class="input-group-append">
        <button
          class="input-group-text"
          @click="$emit('toggle')"
        >
          <svg-icon
            v-if="!reveal"
            type="mdi"
            :path="eye"
            color="#88888f"
          />
          <svg-icon
            v-else
            type="mdi"
            :path="eyeOff"
            color="#88888f"
          />
        </button>
      </div>
    </div>

    <div
      v-if="error"
      class="invalid-feedback"
    >
      {{ error }}
    </div>
    <small
      v-else-if="help"
      class="form-text text-muted"
    >
      {{ help }}
    </small>
  </div>
</template>

<script>
// TODO: Should we avoid duplication with AuInput? Maybe not...
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  components: {
    SvgIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    help: {
      type: String,
      default: null
    },

    // Reveal is exposed as a prop because some forms have multiple
    // password fields that need to be revealed in sync.
    reveal: {
      type: Boolean,
      default: false
    },

    // TODO: Maybe allow multiple errors?
    error: {
      type: String,
      default: null
    }
  },

  data: () => ({
    eye: mdiEyeOutline,
    eyeOff: mdiEyeOffOutline
  }),

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>
