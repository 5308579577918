<template>
  <div
    class="bg-white border-bottom px-0 py-0 fixed-bottom sticky-md-top padSafe noneImprime"
  >
    <div
      class="container d-flex align-items-center py-0 px-0 px-md-4 w-100"
      style="max-width: 900px; min-height: 60px"
    >
      <router-link :to="{ name: homeRouteName }">
        <LogoAude
          class="d-none d-md-block"
          style="width: 80px; cursor: pointer"
        />
      </router-link>

      <DuoNav
        class="ml-auto flex-grow-1 flex-md-grow-0"
      />
    </div>
  </div>
</template>

<script>
import LogoAude from '@/assets/LogoAude.vue'
import utils from '@/api/utils.js'
import DuoNav from '@/components/portal/DuoNav/DuoNav.vue'

export default {
  components: {
    LogoAude,
    DuoNav
  },

  data: () => ({
    homeRouteName: utils.getHome()
  })
}
</script>
