import axios from 'axios'

// Utils

export function checkLogin () {
  if (!localStorage.getItem('user')) {
    return false
  }
  return true
}

export function getHierarchy () {
  return axios.get('/v1/grade-level-groups?with=gradeLevels')
}

export function getGradeLevel (id) {
  return axios.get('/v1/grade-levels/' + id + '?with=topics')
}

export function getGradeLevelSubjects (gradeLevelId, teachingSystemId) {
  return axios.get(process.env.VUE_APP_API_URL + '/v1/grade-levels/' + gradeLevelId + '/subjects?teaching_system_id=' + teachingSystemId)
}

export function getSubjects () {
  return axios.get('/v1/subjects')
}

export function getTeachingSystems () {
  return axios.get('/v1/teaching-systems')
}

export default {
  checkLogin,
  getHierarchy,
  getGradeLevel,
  getSubjects,
  getTeachingSystems,
  getGradeLevelSubjects
}
