import axios from 'axios'

// Artigos
export function listArticles (page) {
  return axios.get('/v1/academic-article-textbooks?page=' + page)
}

export function getArticle (id) {
  return axios.get('/v1/academic-article-textbooks/' + id)
}

export default {
  listArticles,
  getArticle
}
