// FAKE
export function getRandomInt (data) {
  return Math.floor(Math.random() * (data + 56 - 1)) + 5
}
// export function getPercentageOfStudy (month, year, userid, gradelevelid) {
//   const data = [
//     {
//       name: 'Video aulas',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'Questões comentadas',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'Podcast',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'Audio Books',
//       value: this.getRandomInt(month)
//     }
//   ]
//   return data
// }

// export function testeversion () {
//   const data = [
//     {
//       android: {
//         latest_version: '1.2.0',
//         min_version: '1.0.0'
//       },
//       ios: {
//         latest_version: '1.2.0',
//         min_version: '1.0.0'
//       }
//     }
//   ]
//   return data
// }

// export function getFrequency (month, year, userid) {
//   const data = [
//     {
//       name: 'semana 1',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'semana 2',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'semana 3',
//       value: this.getRandomInt(month)
//     },
//     {
//       name: 'semana 4',
//       value: this.getRandomInt(month)
//     }
//   ]
//   return data
// }

// export function getAccessPerTopic (month, year, userid, gradelevelid, subjectid) {
//   // console.log('-- assuntos -- ')
//   // console.log('mês ' + month)
//   // console.log('ano ' + year)
//   // console.log('usuario  ' + userid)
//   // console.log('gradeLevel ' + gradelevelid)
//   // console.log('id da materia ' + subjectid)
//   const data =
//     {
//       name: 'matematica',
//       topics: [
//         {
//           name: 'Frações ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         },
//         {
//           name: 'Equação ' + this.getRandomInt(month),
//           value: this.getRandomInt(month)
//         }
//       ]
//     }
//   return data
// }

export function getPerformance (month, year, userid, gradelevelid, subjectid) {
  // console.log('-- performance -- ')
  // console.log('mês ' + month)
  // console.log('ano ' + year)
  // console.log('usuario  ' + userid)
  // console.log('gradeLevel ' + gradelevelid)
  // console.log('id da materia ' + subjectid)
  const data =
    {
      name: 'Português ' + this.getRandomInt(month),
      topics: [
        {
          name: 'verbos',
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        },
        {
          name: 'topico: ' + this.getRandomInt(month),
          desempenho_do_aluno: this.getRandomInt(month),
          Aproveitamento_total: this.getRandomInt(month),
          Media_de_desempenho_da_turma: this.getRandomInt(month),
          Media_de_aproveitamento_total_da_turma: this.getRandomInt(month)
        }
      ]
    }
  return data
}

export function getDiagnostics () {
  const data = {
    id: 563,
    role_id: 43,
    school_year: 2021,
    version: 1,
    contents: JSON.stringify(this.getDiagnosticTwo())
  }
  return data
}

export function getDiagnosticTwo () {
  const data = {
    weekly_frequency:
    {
      months: [
        {
          number: 1,
          name: 'Janeiro',
          weekly_percentages: [
            21,
            40,
            1,
            36,
            8
          ]
        }, {
          number: 2,
          name: 'Fevereiro',
          weekly_percentages: [
            94,
            65,
            22,
            28
          ]
        }
      ]
    },
    content_accesses:
    {
      months: [
        {
          number: 1,
          name: 'Janeiro',
          media_types: [
            {
              type: 1,
              name: 'Vídeo-aulas',
              percentage: 10
            }, {
              type: 2,
              name: 'Questão Comentada',
              percentage: 15
            }, {
              type: 3,
              name: 'Áudio Livro',
              percentage: 12
            }
          ]
        }, {
          number: 2,
          name: 'Fevereiro',
          media_types: [
            {
              type: 1,
              name: 'Vídeo-aulas',
              percentage: 13
            }, {
              type: 2,
              name: 'Questão Comentada',
              percentage: 15
            }, {
              type: 4,
              name: 'Pod-Cast',
              percentage: 10
            }
          ]
        }
      ]
    },
    topic_accesses:
    {
      months: [
        {
          number: 1,
          name: 'Janeiro',
          topics: [
            {
              id: 1,
              subject_id: 1,
              name: 'Soma',
              accesses: 50
            }, {
              id: 2,
              subject_id: 1,
              name: 'Frações',
              accesses: 20
            }, {
              id: 3,
              subject_id: 1,
              name: 'Subtração',
              accesses: 12
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos1',
              accesses: 32
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              accesses: 51
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos2',
              accesses: 32
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              accesses: 51
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos3',
              accesses: 32
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              accesses: 51
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos4',
              accesses: 32
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              accesses: 51
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos5',
              accesses: 32
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              accesses: 51
            }
          ],
          subjects: [
            {
              id: 1,
              name: 'Matemática'
            }, {
              id: 2,
              name: 'Português'
            }
          ]
        }, {
          number: 2,
          name: 'Fevereiro',
          topics: [
            {
              id: 6,
              subject_id: 3,
              name: 'Globalização',
              accesses: 50
            }, {
              id: 7,
              subject_id: 3,
              name: 'índia',
              accesses: 50
            }, {
              id: 8,
              subject_id: 3,
              name: 'China',
              accesses: 50
            }, {
              id: 9,
              subject_id: 4,
              name: 'Guerra Fria',
              accesses: 50
            }, {
              id: 10,
              subject_id: 4,
              name: 'A Primeira republica',
              accesses: 50
            }
          ],
          subjects: [
            {
              id: 3,
              name: 'Geografia'
            }, {
              id: 4,
              name: 'História'
            }
          ]
        }
      ]
    },
    topic_performance:
    {
      months: [
        {
          number: 1,
          name: 'Janeiro',
          topics: [
            {
              id: 1,
              subject_id: 1,
              name: 'Soma',
              correct_count: 50,
              answered_count: 70,
              school_courses_average_correct_count: 30
            }, {
              id: 2,
              subject_id: 1,
              name: 'Frações',
              correct_count: 20,
              answered_count: 80,
              school_courses_average_correct_count: 20
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos',
              correct_count: 10,
              answered_count: 90,
              school_courses_average_correct_count: 40
            }
          ],
          subjects: [
            {
              id: 1,
              name: 'Matemática'
            }, {
              id: 2,
              name: 'Português'
            }
          ]
        }, {
          number: 2,
          name: 'Fevereiro',
          topics: [
            {
              id: 1,
              subject_id: 1,
              name: 'Frações',
              correct_count: 50,
              answered_count: 100,
              school_courses_average_correct_count: 30
            }, {
              id: 4,
              subject_id: 2,
              name: 'Verbos',
              correct_count: 50,
              answered_count: 100,
              school_courses_average_correct_count: 30
            }, {
              id: 5,
              subject_id: 2,
              name: 'Pronomes',
              correct_count: 50,
              answered_count: 100,
              school_courses_average_correct_count: 30
            }
          ],
          subjects: [
            {
              id: 1,
              name: 'Matemática'
            }, {
              id: 2,
              name: 'Português'
            }
          ]
        }
      ]
    }
  }

  return data
}

export default {
  getRandomInt,
  // getFrequency,
  // getPercentageOfStudy,
  getPerformance,
  // getAccessPerTopic,
  getDiagnostics,
  getDiagnosticTwo
}
