import { types as users } from '@/enums/roleType'

export default [

  {
    path: '/escola/turma',
    name: 'school-course',
    component: () => import('@/views/portal/schoolcourse/SchoolCourses.vue'),
    meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
    children: [
      {
        path: 'mural',
        name: 'school-course.posts',
        component: () => import('@/views/portal/schoolcourse/Posts.vue'),
        meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
        props: true
      },
      {
        path: 'avaliacoes',
        name: 'school-course.exams',
        component: () => import('@/views/portal/Exam/ExamList.vue'),
        meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
        props: true
      },
      {
        path: '/escola/turma/avaliacoes/:idexam',
        name: 'school-course.exam-id',
        component: () => import('@/views/portal/Exam/Exam_id.vue'),
        meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] }
      },
      {
        path: 'configuracoes',
        name: 'school-course.config',
        component: () => import('@/views/portal/schoolcourse/Config.vue'),
        meta: { permissions: [users.Student, users.Teacher, users.Maintainer, users.Coordinator] },
        props: true
      }
    ]
  }
]
