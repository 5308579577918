<template>
  <div
    class="d-flex justify-content-center align-items-sm-center p-sm-2"
    style="min-height: 100vh;"
  >
    <div
      class="au-auth-card px-4 py-4 px-sm-10 py-sm-10 rounded-sm au-login-body-color"
    >
      <div class="text-center mb-4">
        <LogoAude
          height="40px"
        />
        <!-- TODO: Use classes for font family and color -->
        <div
          class="font-weight-bold mt-4"
          style="font-family: 'Montserrat'; font-size: 28px; sans-serif; letter-spacing: -0.04em;"
        >
          Cadastre-se
        </div>
      </div>

      <div
        v-if="enableStudentAlert"
        class="alert alert-info alert-dismissible pr-5"
        role="alert"
      >
        <div class="alert-heading">
          Não é aluno(a)?
        </div>
        <p class="mb-0 small">
          Este formulário é exclusivo para alunos. Caso você seja um(a)
          professor(a) ou coordenador(a), entre em contato com a sua
          instituição e solicite seu acesso.
        </p>
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="enableStudentAlert = false"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <AuInput
        id="invitation_code"
        v-model="invitation_code"
        label="Código da Turma:"
        help="O Código da Turma é fornecido pela sua instituição de ensino."
        :error="formErrors.invitation_code && formErrors.invitation_code[0]"
        :readonly="loading"
        @input="clearInput('invitation_code')"
      />

      <div class="row">
        <div class="col-sm-6">
          <AuInput
            id="first_name"
            v-model="first_name"
            label="Nome:"
            :error="formErrors.first_name && formErrors.first_name[0]"
            @input="clearInput('first_name')"
          />
        </div>

        <div class="col-sm-6">
          <AuInput
            id="last_name"
            v-model="last_name"
            label="Sobrenome:"
            :error="formErrors.last_name && formErrors.last_name[0]"
            @input="clearInput('last_name')"
          />
        </div>
      </div>

      <AuInput
        id="email"
        v-model="email"
        label="E-mail:"
        :error="formErrors.email && formErrors.email[0]"
        @input="clearInput('email')"
      />

      <div class="row">
        <div class="col-sm-6">
          <AuPasswordInput
            id="password"
            v-model="password"
            label="Senha:"
            :reveal="revealPassword"
            :error="formErrors.password && formErrors.password[0]"
            @input="clearInput('password')"
            @toggle="revealPassword = !revealPassword"
          />
        </div>

        <div class="col-sm-6">
          <AuPasswordInput
            id="confirm_password"
            v-model="confirm_password"
            label="Confirme a senha:"
            :reveal="revealPassword"
            :error="formErrors.confirm_password && formErrors.confirm_password[0]"
            @input="validatePassword"
            @toggle="revealPassword = !revealPassword"
          />
        </div>
      </div>

      <button
        class="btn btn-lg btn-block btn-lighten-primary rounded-pill my-5"
        :disabled="(email ? false : true) || loading || !passwordsMatch"
        style="font-weight: 600;"
        @click="register"
      >
        Criar conta
      </button>

      <div class="mt-5 text-center">
        Já tem uma conta?
        <router-link
          :to="{ name: 'Login' }"
          class="au-auth-link"
        >
          Faça login aqui
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LogoAude from '../assets/LogoAude.vue'
import AuInput from '@/components/AudeUi/AuInput'
import AuPasswordInput from '@/components/AudeUi/AuInput/AuPasswordInput'
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import utils from '@/api/utils'

export default {
  components: {
    LogoAude,
    AuInput,
    AuPasswordInput
  },

  mixins: [
    handlesServerValidation
  ],

  layout: 'Auth',

  data: () => ({
    loading: false,
    revealPassword: false,
    enableStudentAlert: true,

    // Form
    first_name: '',
    last_name: '',
    email: '',
    invitation_code: '',
    password: '',
    confirm_password: ''
  }),

  computed: {
    passwordsMatch () {
      return this.password === this.confirm_password
    }
  },

  methods: {
    validatePassword () {
      if (!this.passwordsMatch) {
        this.formErrors.confirm_password = ['As senhas não são iguais.']
        return false
      }

      this.clearInput('confirm_password')
      return true
    },

    async register () {
      if (this.validatePassword() === false) {
        return
      }

      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        invitation_code: this.invitation_code
      }
      this.loading = true
      try {
        await this.$api.newUser(data)
        await this.login()
      } catch (e) {
        this.loading = false
        utils.showLogs(e.response)
        this.getErrors(e)
      }
      this.loading = false
    },

    async login () {
      const data = {
        email: this.email,
        password: this.password
      }
      this.loading = true
      try {
        await this.$auth.login(data)
        await this.$api.me()
        this.$router.push({ name: 'Enrollment' })
      } catch (e) {
        this.loading = false
        this.getErrors(e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
// É necessário importar esses arquivos do Bootstrap pois utilizamos o mixin
// media-breakpoint-up(). Seria interessante ter essa função disponível em todos
// os blocos de estilo dos Sigle File Components do Vue.js no futuro evitar
// esses imports.
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_variables.scss';

.au-login-body-color {
  color: #5E6065;
}

.au-auth-card {
  flex-grow: 1;
  width: 100%;
  max-width: 360px;
  background-color: white;

  @include media-breakpoint-up(sm) {
    max-width: 540px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);;
  }
}

.au-auth-link {
  color: #A37B29;
  text-decoration-color: #A37B29;
}

</style>
