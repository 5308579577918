<template>
  <!-- TODO: Unify Login.vue and Register.vue card -->
  <div
    class="vh-100 d-flex justify-content-center align-items-center"
  >
    <div
      class="au-auth-card py-10 px-4 px-sm-10 rounded-sm au-login-body-color"
    >
      <div class="text-center mb-8">
        <LogoAnimated />
        <!-- TODO: Use classes for font family and color -->
        <div
          class="mt-4 font-weight-bold"
          style="font-family: 'Montserrat'; font-size: 28px; sans-serif; letter-spacing: -0.04em;"
        >
          Plataforma Educacional
        </div>
      </div>

      <Login
        v-if="state === 'lookup'"
        @sendEmail="toEmail($event)"
      />

      <ConfirmSendEmail
        v-else-if="state === 'confirmEmail'"
        :msg="msg"
        @toLogin="state = 'lookup'"
      />
    </div>
  </div>
</template>

<script>
import LogoAnimated from '../assets/LogoAnimated.vue'
import Login from '../components/auth/Login.vue'
import ConfirmSendEmail from '../components/auth/ConfirmSendEmail.vue'

export default {
  components: {
    LogoAnimated,
    Login,
    ConfirmSendEmail
  },

  layout: 'Auth',

  data: () => ({
    state: 'lookup',
    msg: ''
  }),
  created () {
    this.verifyLogin()
  },

  methods: {
    verifyLogin () {
      if (this.$auth.checkLogin()) {
        this.$router.push({ name: 'Enrollment' })
      }
    },

    toEmail (data) {
      this.msg = data
      this.state = 'confirmEmail'
    }
  }
}
</script>

<style lang="scss" scoped>
// É necessário importar esses arquivos do Bootstrap pois utilizamos o mixin
// media-breakpoint-up(). Seria interessante ter essa função disponível em todos
// os blocos de estilo dos Sigle File Components do Vue.js no futuro evitar
// esses imports.
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_variables.scss';

// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
// a {
//   color: #000;
// }

.au-login-body-color {
  color: #5E6065;
}

.au-auth-card {
  flex-grow: 1;
  width: 100%;
  max-width: 360px;
  background-color: white;

  @include media-breakpoint-up(sm) {
    max-width: 420px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }
}

</style>
