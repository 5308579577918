<template>
  <div class="">
    <b-container class="vh-100">
      <b-row
        align-h="center"
        class="vh-100"
      >
        <b-col style="max-width: 300px">
          <LogoAude style="height: 50px; margin-top: 80px;" />
          <div
            class="text-center border border-primary rounded  mt-8"
            style="padding: 20px"
          >
            <img
              src="../assets/error404.svg"
              class="img-fluid"
              style="max-width: 180px"
            >
            <h3 class="mt-6 mb-6">
              <strong> Ops!</strong>
            </h3>
            <span>
              Não encontramos a página que esta procurando :(
            </span>
          </div>
          <div class="d-flex flex-column mt-10">
            <AuButton
              class="btn-aude-text"
              label="Voltar para página anterior"
              @click="$router.go(-1)"
            />
            <AuButton
              class="btn-aude-text"
              label="Voltar para página inicial"
              @click="goHome()"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import utils from '../api/utils.js'
import LogoAude from '../assets/LogoAude.vue'
export default {
  components: {
    LogoAude
  },

  layout: 'Auth',

  methods: {
    goHome () {
      const home = utils.getHome()
      this.$router.push({ name: home })
    }
  }
}
</script>
