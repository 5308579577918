<template>
  <b-modal
    v-bind="$attrs"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    centered
    title="Ops..."
    v-on="$listeners"
  >
    <template v-if="message">
      <div class="mt-10 mb-10 text-center">
        {{ message }}
      </div>
      <AuButton
        class="mt-3 btn-aude-primary btn-aude-block"
        label="OK"
        @click="$emit('ok')"
      />
    </template>

    <template v-else>
      <div class="mt-10 mb-10 text-center">
        <span>
          Ocorreu um erro na pagina,
          <b-link
            style="text-decoration: underline"
            @click="$router.go()"
          >
            clique aqui para atualizar
          </b-link>
        </span>
        <p>
          ou tente novamente mais tarde.
        </p>
      </div>
      <AuButton
        class="mt-3 btn-aude-primary btn-aude-block"
        label="Voltar para página anterior"
        @click="$emit('back')"
      />
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'

export default {
  model: BModal.options.model,

  props: {
    message: {
      type: String,
      default: null
    }
  }
}
</script>
