<template>
  <router-link
    v-slot="{ href, navigate, isActive }"
    :to="item.to"
    custom
  >
    <a
      :href="href"
      class="duo-bar-btn"
      :class="[isActive && 'router-link-active']"
      @click="navigate"
    >
      <img
        :src="isActive ? item.icon : item.iconPb"
        style="width: 48px;"
      >
      <div class="duo-bar-btn-text">{{ item.title }}</div>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
// É necessário importar esses arquivos do Bootstrap pois utilizamos o mixin
// media-breakpoint-up(). Seria interessante ter essa função disponível em todos
// os blocos de estilo dos Sigle File Components do Vue.js no futuro evitar
// esses imports.
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_variables.scss';

.duo-bar-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 6px 16px;
  font-size: 13px;
  font-weight: bold;
  color: #AFAFB6;
  border-top: 2px solid white;
  transition: color .2s;

  &:focus, &:hover {
    color: #88888F;
    text-decoration: none;
  };

  &.router-link-active {
    border-top-color: #ffd30a;
    color: #55555C;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    border-top: none;
    border-bottom: 2px solid white;
    font-size: 16px;

    .duo-bar-btn-text {
      margin-left: 4px;
    }

    &.router-link-active {
      border-bottom-color: #ffd30a;
    }
  }
}

</style>
