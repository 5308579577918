import store from '@/store'
import { types as roleTypes } from '@/enums/roleType.js'
import * as Sentry from '@sentry/vue'

export default {
  getUser () {
    return JSON.parse(localStorage.getItem('user'))
  },

  objectMap (obj, callback) {
    return Object.fromEntries(
      Object.entries(obj).map(
        ([k, v], i) => [k, callback(v, k, i)]
      )
    )
  },

  currentObjData (data) {
    const newItems = {}
    for (const variable in data) {
      newItems[variable] = data[variable]
    }
    return newItems
  },

  newData (newData, oldData) {
    const newItems = {}
    for (const variable in newData) {
      if (newData[variable] !== oldData[variable]) {
        newItems[variable] = newData[variable]
      }
    }
    return newItems
  },

  setRole (role) {
    localStorage.setItem('currentRole', JSON.stringify(role))

    // INFO: Setamos o role na store também como
    // conveniência, para facilitar a migração.
    store.commit('setRole', role)

    Sentry.setContext('role', {
      id: role.id
    })

    this.setHome(role)
    this.setInstitution(role.institution)
  },

  /**
   * Seta a home de acordo com o role fornecido. A home é salva no localstorage.
   */
  setHome (role) {
    let home = 'home-learn'

    if ([roleTypes.Teacher, roleTypes.Maintainer, roleTypes.Coordinator].includes(role.type)) {
      home = 'home-institution'
    }

    localStorage.setItem('home', home)
  },

  getHome () {
    return localStorage.getItem('home')
  },

  setInstitution (data) {
    localStorage.setItem('Institution', JSON.stringify(data))
  },

  setSubjects (data) {
    localStorage.setItem('Subjects', JSON.stringify(data))
  },

  setHierarchy (data) {
    localStorage.setItem('Hierarchy', JSON.stringify(data))
    this.setGradeLevels(data)
  },

  setGradeLevels (data) {
    const gradelevelGroup = data
    const levels = gradelevelGroup.map((item) => {
      return item.grade_levels.map((level) => {
        return {
          name: item.name + ' - ' + level.name,
          short_name: item.short_name !== null ? item.short_name + ' - ' + level.name : item.name + ' - ' + level.name,
          slug: level.slug,
          id: level.id
        }
      })
    })
    localStorage.setItem('GradeLevels', JSON.stringify(levels.flatMap((item) => item)))
  },

  setSchoolCourses (schoolCourses) {
    localStorage.setItem('SchoolCourses', JSON.stringify(schoolCourses))
    // verificar se as turmas são cobertas pelo contrato
    this.checkContractItems()
  },

  checkContractItems () {
    let contractItemIds = []
    const contract = store.state.contract

    if ([roleTypes.Student].includes(this.getRole().type)) {
      const schoolCourses = this.getSchoolCourses()
      contractItemIds = schoolCourses.map((item) => item.contract_grade_level_item_id)
      const contractItens = contract.contract_grade_level_items.filter((item) => { return contractItemIds.includes(item.id) })
      this.setContractItems(contractItens)
    }
    if ([roleTypes.Teacher, roleTypes.Maintainer, roleTypes.Coordinator].includes(this.getRole().type)) {
      const contractItens = contract.contract_grade_level_items
      this.setContractItems(contractItens)
    }
  },
  setContractItems (contractItems) {
    localStorage.setItem('ContractItems', JSON.stringify(contractItems))
  },
  getContractItems () {
    return JSON.parse(localStorage.getItem('ContractItems'))
  },

  getInformNews () {
    return true
  },
  setInformNews (data) {
    localStorage.setItem('platformNewsDuoNav', data)
  },

  getHierarchy () {
    return JSON.parse(localStorage.getItem('Hierarchy'))
  },
  getSchoolCourses () {
    return JSON.parse(localStorage.getItem('SchoolCourses'))
  },
  getInstitution () {
    return JSON.parse(localStorage.getItem('Institution'))
  },

  getRole () {
    return JSON.parse(localStorage.getItem('currentRole'))
  },

  getGradeLevels () {
    return JSON.parse(localStorage.getItem('GradeLevels'))
  },

  getSubjects () {
    return JSON.parse(localStorage.getItem('Subjects'))
  },

  setAudeAv (audeAv) {
    localStorage.setItem('AudeAv', JSON.stringify(audeAv))
    store.commit('setAvAude', audeAv)
  },
  getAudeAv () {
    return JSON.parse(localStorage.getItem('AudeAv'))
  },

  clearAudeAv () {
    localStorage.removeItem('AudeAv')
  },

  showFeedback (statusType, msgfeedback) {
    const alertToast = {
      status: true,
      // statusType : fail || success
      type: statusType,
      msg: msgfeedback
    }
    store.commit('setAlertFeedBack', alertToast)
  },
  getGradeLevelname (id) {
    if (id === undefined || id === null) {
      return ''
    }
    const gradelevels = JSON.parse(localStorage.getItem('GradeLevels'))
    const response = gradelevels.find((item) => item.id === parseInt(id))
    if (response === undefined || response === null) {
      return 'erro ao exibir segmento e ano'
    }
    return response.short_name ? response.short_name : response.name
  },

  deleteEnrollment () {
    store.commit('setRole', null)
    store.commit('setItemToLearn', null)
    store.commit('setSubjectLearn', null)
    localStorage.removeItem('currentRole')
    Sentry.setContext('role', null)

    localStorage.removeItem('Institution')
    localStorage.removeItem('ContractItems')
    localStorage.removeItem('home')
    localStorage.removeItem('Contract')
    localStorage.removeItem('Subjects')
    localStorage.removeItem('Hierarchy')
    localStorage.removeItem('GradeLevels')
    localStorage.removeItem('TeachingSystems')
    localStorage.removeItem('AvailableSchoolCourseLevels')
    localStorage.removeItem('SchoolCourses')
    localStorage.removeItem('Permissions')
  },

  size (value) {
    if (!value) return 0
    return value.size || value.length || Object.keys(value).length
  },

  getSubjectname (id) {
    if (id === undefined || id === null) {
      return ''
    }
    const subject = JSON.parse(localStorage.getItem('Subjects'))
    const response = subject.find((item) => item.id === parseInt(id))
    if (response === undefined || response === null) {
      return 'Matéria não encontrada'
    }
    return response.name
  },
  getLevelName (id) {
    if (id === undefined || id === null) {
      return ''
    }
    const gradeLevel = JSON.parse(localStorage.getItem('GradeLevels'))
    const response = gradeLevel.find((item) => item.id === parseInt(id))
    if (response === undefined || response === null) {
      return 'Ano não encontrado'
    }
    return response.name
  },

  showLogs (err) {
    // TODO: Avaliar o uso da NODE_ENV em vez de uma variável específica.
    if (process.env.VUE_APP_LOG === 'true') {
      console.log(err)
    }
  },

  makeid (length = 5) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },

  checkPermission (to) {
    const role = this.getRole()
    return to.meta.permissions.includes(role.type)
  },

  setItemToLearn (itemToLearn) {
    store.commit('setItemToLearn', itemToLearn)
  },
  setTopicToLearn (topicLearn) {
    store.commit('setTopicLearn', topicLearn)
  },
  setSubjectToLearn (subjectId) {
    const searchSubject = this.getSubjects().find((item) => item.id === subjectId)
    const subject = searchSubject ?? null
    store.commit('setSubjectLearn', subject)
  }
}
