import axios from 'axios'

// Turmas
export function newSchoolCourse (data) {
  return axios.post('/v1/school-courses', data)
}

export function updateSchoolCourse (id, data) {
  return axios.put('/v1/school-courses/' + id, data)
}

export function getSchoolCourses (id) {
  if (id) {
    return axios.get('/v1/school-courses?grade_level_id=' + id)
  }

  return axios.get('/v1/school-courses')
}

export function getSchoolCourse (id) {
  return axios.get('/v1/school-courses/' + id + '?with=schoolCourseSubjects,teachers.user,students.user')
}

export function getSchoolCourseTeachers () {
  return axios.get('/v1/teachers')
}

export function newSchoolCourseTeacher (id, data) {
  return axios.post('/v1/school-courses/' + id + '/teachers', data)
}

export function deleteSchoolCourseTeacher (id, idRoleTeacher) {
  return axios.delete('/v1/school-courses/' + id + '/teachers/' + idRoleTeacher)
}

export function getSchoolCourseStudents () {
  return axios.get('/v1/students')
}

export function newSchoolCourseStudent (id, data) {
  return axios.post('/v1/school-courses/' + id + '/students', data)
}

export function deleteSchoolCourseStudent (id, idRoleStudent) {
  return axios.delete('/v1/school-courses/' + id + '/students/' + idRoleStudent)
}

export function newSchoolCourseSubject (id, data) {
  return axios.post('/v1/school-courses/' + id + '/subjects', data)
}

export function deleteSchoolCourseSubject (id) {
  return axios.delete('/v1/subjects/' + id)
}

export function getSchoolCoursePosts (id, page, subjectid) {
  let url = '/v1/school-courses/' + id + '/school-course-posts?page=' + page + '&with=schoolCourseSubject,role'
  if (subjectid) {
    url += '&school_course_subject_id=' + subjectid
  }
  return axios.get(url)
}

export function getSchoolCoursePostID (id) {
  return axios.get('/v1/school-course-posts/' + id + '?with=role')
}

export function newSchoolCoursePost (id, data) {
  return axios.post('/v1/school-courses/' + id + '/school-course-posts', data)
}

export function newSchoolCoursePostComment (id, data) {
  return axios.post('/v1/school-course-posts/' + id + '/school-course-post-comments', data)
}

export function getSchoolCoursePostComment (id) {
  return axios.get('/v1/school-course-posts/' + id + '/school-course-post-comments?with=role')
}

export function updateSchoolCoursePost (id, data) {
  return axios.put('/v1/school-course-posts/' + id, data)
}

export function deleteSchoolCoursePost (id) {
  return axios.delete('/v1/school-course-posts/' + id)
}

export function deleteSchoolCoursePostComment (id) {
  return axios.delete('/v1/school-course-post-comments/' + id)
}

export function updateSchoolCourseComment (id, data) {
  return axios.put('/v1/school-course-post-comments/' + id, data)
}

export function enterWithInvitationCode (code) {
  return axios.post('/v1/school-courses/roles/fromInvitation', code)
}

export default {
  newSchoolCourse,
  updateSchoolCourse,
  getSchoolCourses,
  getSchoolCourse,
  getSchoolCourseTeachers,
  newSchoolCourseTeacher,
  deleteSchoolCourseTeacher,
  getSchoolCourseStudents,
  newSchoolCourseStudent,
  deleteSchoolCourseStudent,
  newSchoolCourseSubject,
  deleteSchoolCourseSubject,

  getSchoolCoursePosts,
  newSchoolCoursePost,
  updateSchoolCoursePost,
  deleteSchoolCoursePost,
  getSchoolCoursePostID,

  newSchoolCoursePostComment,
  getSchoolCoursePostComment,
  updateSchoolCourseComment,
  deleteSchoolCoursePostComment,

  enterWithInvitationCode
}
