import axios from 'axios'

// AUDE TV

export function ContentID (id) {
  return axios.get('/v1/media-contents/' + id + '?with=sources')
}

export function ContentperTopicID (id) {
  return axios.get('/v1/topics/' + id + '/media-contents')
}

export function getVolume (gradeLevel, teachingSystem) {
  return axios.get('/v1/grade-levels/' + gradeLevel + '/volumes?teaching_system_id=' + teachingSystem)
}

export function getTopicsAudetv (subject, gradeLevel, teachingSystem, volume) {
  return axios.get('/v1/subjects/' + subject + '/topics?grade_level_id=' + gradeLevel + '&teaching_system_id=' + teachingSystem + '&volume_id=' + volume)
}

export function favoriteContent (contentId) {
  return axios.post('/v1/media-contents/' + contentId + '/favorite')
}

export function unfavoriteContent (contentId) {
  return axios.post('/v1/media-contents/' + contentId + '/unfavorite')
}

export function favorites () {
  return axios.get('/v1/favorites')
}

export function searchContents (query, type, gradelevel, pageid) {
  let url = '/v1/audetv/search?query=' + encodeURIComponent(query)
  if (type !== null) {
    url += '&type=' + encodeURIComponent(type)
  }
  if (gradelevel !== null && gradelevel !== undefined) {
    url += '&grade_level_id=' + encodeURIComponent(gradelevel)
  }
  url += '&page=' + pageid
  //  else if (levelgroup !== null) {
  //   url += '&grade_level_group_id=' + encodeURIComponent(levelgroup)
  // }
  return axios.get(url)
}

export default {
  ContentID,
  favoriteContent,
  unfavoriteContent,
  favorites,
  getVolume,
  getTopicsAudetv,
  ContentperTopicID,
  searchContents
}
