<template>
  <b-modal
    v-bind="$attrs"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    centered
    v-on="$listeners"
  >
    <div
      class="text-center"
      style="color: gray"
    >
      <SemConexao class="mb-2" />
      <h4
        class="font-weight-bold mb-6"
      >
        Ops!
      </h4>
      <p class="mb-0">
        Parece que estamos desconectados :(
      </p>
      <p>
        Por favor verifique a sua conexão com a internet.
      </p>
      <br>
    </div>
  </b-modal>
</template>

<script>
import SemConexao from '@/assets/SemConexao.vue'
import { BModal } from 'bootstrap-vue/esm/components/modal'

export default {
  components: {
    SemConexao
  },

  model: BModal.options.model
}
</script>
