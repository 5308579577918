// import axios from '@/axios'
import authRequests from '@/api/requests/auth.js'
import schoolCourseRequests from '@/api/requests/schoolcourse.js'
import teacherRequests from '@/api/requests/teachers.js'
import coordinatorRequests from '@/api/requests/coordinators.js'
import studentRequests from '@/api/requests/students.js'
import exercisesRequests from '@/api/requests/exercises.js'
// import { startQuiz, nextQuestion, submitAnswer, skipQuestion } from '@/api/requests/exercises.js'
import institutionRequests from '@/api/requests/institution.js'
import usersRequests from '@/api/requests/users.js'
import audeTvRequests from '@/api/requests/audetv.js'
import { checkLogin, getHierarchy, getGradeLevel, getSubjects, getTeachingSystems, getGradeLevelSubjects } from '@/api/requests/utils.js'

import fakeRequests from '@/api/requests/fake.js'
import textbookRequests from '@/api/requests/textbook.js'
import articles from '@/api/requests/articles.js'
import exams from '@/api/requests/exam.js'
import diagnostics from '@/api/requests/diagnostic.js'
import questionBank from '@/api/requests/questionBank.js'
import audeExam from '@/api/requests/audeExam.js'

export default {

  // UTILS
  checkLogin,
  getHierarchy,
  getGradeLevel,
  getSubjects,
  getTeachingSystems,
  getGradeLevelSubjects,
  ...diagnostics,
  ...exams,
  ...questionBank,
  ...audeExam,

  // usado para qualquer teste
  ...fakeRequests,

  // Autenticação portal
  ...authRequests,

  // Usuário e matrículas
  ...usersRequests,

  // AUDETV - CONTEUDOS
  ...audeTvRequests,

  // escola
  ...institutionRequests,

  // professores
  ...teacherRequests,

  // Coordenadores
  ...coordinatorRequests,

  // alunos
  ...studentRequests,

  // Turmas
  ...schoolCourseRequests,

  // exercicios
  ...exercisesRequests,

  // material didático
  ...textbookRequests,

  // Artigos
  ...articles
}
