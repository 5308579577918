<template>
  <div>
    <div
      class="sticky-top bg-white border-bottom"
    >
      <b-navbar
        variant="white"
        class="container container-aude d-flex justify-content-center"
      >
        <b-navbar-nav
          class="left"
        >
          <LogoAude
            style="width: 80px;"
          />
        </b-navbar-nav>
        <b-navbar-nav
          class="ml-auto d-flex align-items-center"
        >
          <b-nav-item-dropdown
            right
            no-caret
          >
            <template #button-content>
              <span class="navbar-toggler-icon" />
            </template>
            <b-dropdown-item @click="logout">
              Sair
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <slot />
  </div>
</template>

<script>
import LogoAude from '../assets/LogoAude.vue'
export default {
  components: {
    LogoAude
  },

  data: () => ({
    show: false
  }),
  created () {
    // this.fetchHomeContent()
  },

  methods: {

    async logout () {
      try {
        await this.$auth.logout()
        this.$router.push({ name: 'Login' })
      } catch (e) {
        this.$router.push({ name: 'Login' })
        return false
      }
    }
  }
}

</script>
