<template>
  <div>
    <b-overlay
      :show="show"
      rounded="lg"
    >
      <!-- <div
        class="sticky-top bg-white border-bottom"
      >
        <b-navbar
          variant="white"
          class="container d-flex justify-content-center"
          style="max-width: 900px"
        >
          <b-navbar-nav
            class="left"
            @click="goHome"
          >
            <LogoAude
              style="width: 80px; cursor: pointer"
            />
          </b-navbar-nav>
          <b-navbar-nav
            class="ml-auto"
          >
            <b-nav-item-dropdown
              v-if="permission"
              right
              no-caret
            >
              <template v-slot:button-content>
                <span class="navbar-toggler-icon" />
              </template>
              <b-dropdown-item to="/matriculas">
                Trocar Matricula
              </b-dropdown-item>
              <b-dropdown-item @click="logout">
                Sair
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </div> -->
      <slot />
    </b-overlay>
  </div>
</template>

<script>
// import LogoAude from '@/assets/LogoAude.vue'
import utils from '@/api/utils.js'

export default {

  components: {
    // LogoAude
  },

  data: () => ({
    show: false,
    institution: {},
    notifications: [],
    user: [],
    role: [],
    permission: true
  }),

  created () {
    this.fetchHomeContent()
  },

  methods: {
    async fetchHomeContent () {
      this.show = true

      const role = utils.getRole()
      if (utils.size(role) === 0) {
        this.permission = false
        this.$router.push({ name: 'Enrollment' })
        return false
      }

      this.user = utils.getUser()
      const roles = await this.$api.roles(this.user.id)

      if (utils.size(roles) === 0) {
        this.permission = false
        this.$router.push({ name: 'Enrollment' })
        return false
      }
      this.getInstitution()
      this.show = false
    },

    getInstitution () {
      this.institution = utils.getInstitution()
    },

    goHome () {
      const home = utils.getHome()
      if (this.$route.name === home) {
        return
      }
      this.$router.push({ name: home })
    },

    async logout () {
      try {
        await this.$auth.logout()
        this.$router.push({ name: 'Login' })
      } catch (e) {
        this.$router.push({ name: 'Login' })
        return false
      }
    }
  }
}
</script>
