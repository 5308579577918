<template>
  <div class="container">
    <div
      v-if="hasRole"
    >
      <h6
        class="mb-6 mt-10 text-center"
      >
        {{ msg }}
      </h6>
      <div class="row justify-content-center">
        <div
          v-for="role in roles"
          :key="role.id"
          class="col"
          style="max-width: 200px;"
        >
          <b-button
            class="shadow-sm mt-4"
            variant="transparent"
            block
            @click="$emit('selectedRole', role)"
          >
            <img
              :src="role.type === 0 ? require('@/assets/avatar.png') : require('@/assets/escola_default.svg')"
              width="150px"
              height="150px"
              :class="roleInstitutionIsDefaulting(role) ? 'disableImage' : '' "
            >
            <p class="mb-0 mt-3">
              {{ role.institution.name ? role.institution.name : '' }}
            </p>
            <span style="font-size: 13px;">
              {{ userTypeDisplay(role.type) }} -  {{ user.name ? user.name : '' }}
            </span>
          </b-button>
        </div>
      </div>
    </div>

    <div
      v-else
      class="mt-16 text-center"
    >
      <p>Você ainda não possui nenhuma matrícula ativa.</p>
      <p>Entre em contato com a sua instituição para regularizar seu cadastro.</p>
    </div>
  </div>
</template>
<script>

import { getTextForDisplay as userTypeDisplay } from '@/enums/roleType'
export default {
  props: {
    roles: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    hasRole: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    msg: 'Clique na sua matrícula'
  }),

  watch: {
    roles () {
      if (this.roles.length > 1) {
        this.msg = 'Com qual matrícula deseja acessar o portal?'
      } else {
        this.msg = 'Clique na sua matrícula'
      }
    }
  },
  created () {
    if (this.roles.length > 1) {
      this.msg = 'Com qual matrícula deseja acessar o portal?'
    }
  },

  methods: {
    roleInstitutionIsDefaulting (role) {
      if (role.institution.is_defaulting) {
        return true
      }
      return false
    },

    userTypeDisplay
  }
}
</script>
