import axios from 'axios'

// Escola

export async function getInstitution (id) {
  const institution = await axios.get('/v1/institutions/' + id)
  return institution.data.data
}

export async function getContract () {
  const institution = await axios.get('/v1/contracts/current?with=gradeHierarchy')
  return institution.data.data
}

export function uploadLogo (data) {
  return axios.post('/v1/institution-logo', data)
}

export function payments (data) {
  return axios.get('/v1/institution-payments', data)
}

export function featureFlags (data) {
  return axios.get('/v1/feature-flags', data)
}

export default {
  getInstitution,
  getContract,
  uploadLogo,
  payments,
  featureFlags
}
