<template>
  <div class="bg-white d-flex justify-content-around px-0">
    <DuoNavBtn
      v-for="item in menu"
      :key="item.title"
      :item="item"
    />
  </div>
</template>

<script>
import DuoNavBtn from './DuoNavBtn.vue'
import { types as users } from '@/enums/roleType'

export default {
  components: {
    DuoNavBtn
  },

  data: () => ({
    menu: []
  }),

  created () {
    this.buildMenu()
  },

  methods: {
    buildMenu () {
      const isEmployee = [
        users.Teacher,
        users.Maintainer,
        users.Coordinator
      ].includes(this.$store.state.role.type)

      if (isEmployee) {
        const itens = [{
          title: 'Escola',
          to: { name: 'home-institution' },
          icon: require('@/assets/navbar/nav-escola-active.svg'),
          iconPb: require('@/assets/navbar/nav-escola.svg')
        }, {
          title: 'Conteúdos',
          to: { name: 'pre-learn' },
          icon: require('@/assets/navbar/nav-aprender-active.svg'),
          iconPb: require('@/assets/navbar/nav-aprender.svg')
        }]
        this.menu = this.menu.concat(itens)
      } else {
        const itens = [{
          title: 'Aprender',
          to: { name: 'home-learn' },
          icon: require('@/assets/navbar/nav-aprender-active.svg'),
          iconPb: require('@/assets/navbar/nav-aprender.svg')
        }, {
          title: 'Escola',
          to: { name: 'school-course' },
          icon: require('@/assets/navbar/nav-escola-active.svg'),
          iconPb: require('@/assets/navbar/nav-escola.svg')
        }]
        this.menu = this.menu.concat(itens)
      }

      const itens = [{
        title: 'Diagnóstico',
        to: { name: 'Diagnostics' },
        icon: require('@/assets/navbar/nav-diag360-active.svg'),
        iconPb: require('@/assets/navbar/nav-diag360.svg')
      }, {
        title: 'Perfil',
        to: { name: 'profile' },
        icon: require('@/assets/navbar/nav-profile-active.svg'),
        iconPb: require('@/assets/navbar/nav-profile.svg')
      }]
      this.menu = this.menu.concat(itens)
    }
  }
}
</script>
