import Vue from 'vue'
import auth from '@/auth/auth'

Object.defineProperties(Vue.prototype, {
  $auth: {
    get () {
      return auth
    }
  }
})

export default auth
