<template>
  <div class="form-group">
    <label :for="id">{{ label }}</label>
    <input
      :id="id"
      class="form-control form-control-lg"
      :class="{ 'is-invalid': error }"
      v-bind="$attrs"
      :value="value"
      :type="type"
      v-on="listeners"
    >
    <div
      v-if="error"
      class="invalid-feedback"
    >
      {{ error }}
    </div>
    <small
      v-else-if="help"
      class="form-text text-muted"
    >
      {{ help }}
    </small>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    help: {
      type: String,
      default: null
    },

    // TODO: Maybe allow multiple errors?
    error: {
      type: String,
      default: null
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>
