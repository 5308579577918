<template>
  <b-modal
    id="modal-Recuse-Terms"
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    no-stacking
    v-on="$listeners"
  >
    <div class="row d-flex justify-content-center">
      <div class="col-11 text-center mt-10 mb-10">
        Não aceitando os termos de uso e políticas de privacidade, não será possível utilizar os serviços do Portal Educacional. Deseja recusar mesmo assim ?
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-8">
        <AuButton
          class="mb-6 btn-aude-primary btn-aude-block"
          label="Ler novamente"
          @click="$emit('readTerms')"
        />
        <AuButton
          class="mb-6 btn-aude-secondary btn-aude-block"
          label="Recusar"
          @click="logout"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'
export default {
  model: BModal.options.model,

  data: () => ({
    acceptTerms: false
  }),

  methods: {

    async logout () {
      try {
        await this.$auth.logout()
        this.$router.push({ name: 'Login' })
      } catch (e) {
        this.$router.push({ name: 'Login' })
        return false
      }
    }
  }
}
</script>
