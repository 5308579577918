import Vue from 'vue'
import {
  BootstrapVue,

  BIcon,
  BIconArrowLeftShort,
  BIconArrowDown,
  BIconArrowUp,
  BIconBarChartLine,
  BIconCaretDownFill,
  BIconCaretUpFill,
  BIconChatLeftText,
  BIconCheckCircleFill,
  BIconChevronLeft,
  BIconEnvelope,
  BIconExclamationOctagonFill,
  BIconExclamationTriangleFill,
  BIconEyeFill,
  BIconEyeSlashFill,
  BIconHeart,
  BIconHeartFill,
  BIconList,
  BIconLock,
  BIconPencil,
  BIconPerson,
  BIconPersonPlus,
  BIconPersonPlusFill,
  BIconPlus,
  BIconQuestionCircleFill,
  BIconSearch,
  BIconThreeDotsVertical,
  BIconTrash,
  BIconTrashFill,
  BIconUnlock,
  BIconX,
  BIconXCircle,
  BIconGear,
  BIconTelephone,
  BIconCalendarDate,
  BIconGraphUp,
  BIconBook,
  BIconPeople,
  BIconPrinter,
  BIconTypeBold,
  BIconTypeItalic,
  BIconImage,
  BIconCalculator,
  BIconPatchCheckFill

} from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)

Vue.component('BIcon', BIcon)
Vue.component('BIconArrowLeftShort', BIconArrowLeftShort)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconBarChartLine', BIconBarChartLine)
Vue.component('BIconCaretDownFill', BIconCaretDownFill)
Vue.component('BIconCaretUpFill', BIconCaretUpFill)
Vue.component('BIconChatLeftText', BIconChatLeftText)
Vue.component('BIconCheckCircleFill', BIconCheckCircleFill)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconEnvelope', BIconEnvelope)
Vue.component('BIconExclamationOctagonFill', BIconExclamationOctagonFill)
Vue.component('BIconExclamationTriangleFill', BIconExclamationTriangleFill)
Vue.component('BIconEyeFill', BIconEyeFill)
Vue.component('BIconEyeSlashFill', BIconEyeSlashFill)
Vue.component('BIconHeart', BIconHeart)
Vue.component('BIconHeartFill', BIconHeartFill)
Vue.component('BIconList', BIconList)
Vue.component('BIconLock', BIconLock)
Vue.component('BIconPencil', BIconPencil)
Vue.component('BIconPerson', BIconPerson)
Vue.component('BIconPersonPlus', BIconPersonPlus)
Vue.component('BIconPersonPlusFill', BIconPersonPlusFill)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconQuestionCircleFill', BIconQuestionCircleFill)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconThreeDotsVertical', BIconThreeDotsVertical)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconUnlock', BIconUnlock)
Vue.component('BIconX', BIconX)
Vue.component('BIconXCircle', BIconXCircle)
Vue.component('BIconGear', BIconGear)
Vue.component('BIconTelephone', BIconTelephone)
Vue.component('BIconCalendarDate', BIconCalendarDate)
Vue.component('BIconGraphUp', BIconGraphUp)
Vue.component('BIconBook', BIconBook)
Vue.component('BIconPeople', BIconPeople)
Vue.component('BIconPrinter', BIconPrinter)
Vue.component('BIconTypeBold', BIconTypeBold)
Vue.component('BIconTypeItalic', BIconTypeItalic)
Vue.component('BIconImage', BIconImage)
Vue.component('BIconCalculator', BIconCalculator)
Vue.component('BIconPatchCheckFill', BIconPatchCheckFill)
Vue.component('BIconTrashFill', BIconTrashFill)
