import axios from 'axios'

// Professores
export function getTeachers () {
  return axios.get('/v1/teachers')
}

export function getTeacher (id) {
  return axios.get('/v1/teachers/' + id + '?with=schoolCourses')
}

export function newTeacher (data) {
  return axios.post('/v1/teachers', data)
}

export function disableTeacher (roleId) {
  return axios.post('/v1/role/' + roleId + '/disable')
}

export function enableTeacher (roleId) {
  return axios.post('/v1/role/' + roleId + '/enable')
}

export default {
  getTeachers,
  getTeacher,
  newTeacher,
  disableTeacher,
  enableTeacher
}
