
export default {
  data: () => ({
    formErrors: {}
  }),

  methods: {
    // TODO: Função get sem retorno, corrigir nome.
    getErrors (err) {
      // Early throw, para relançar caso o erro não tenha a ver com validação.
      if (!err.response?.data?.errors) {
        throw err
      }

      // TODO: Avaliar o uso da NODE_ENV em vez de uma variável específica.
      // if (process.env.VUE_APP_LOG === 'true') {
      //   console.log(err)
      // }

      this.formErrors = err.response.data.errors
    },

    cleanFormErrors () {
      this.formErrors = {}
    },

    // TODO: rename to clearFormError(field)
    clearInput (data) {
      this.formErrors[data] = ''
    }
  }
}
