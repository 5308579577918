import api from '@/plugins/api'

export function getCookie () {
  return api.getCookie()
}

export function checkLogin () {
  if (!localStorage.getItem('user')) {
    return false
  }

  return true
}

export function login (data) {
  return api.login(data)
}

export async function lookUp (data) {
  await this.getCookie()
  return api.lookUp(data)
}

export function logout () {
  return api.logout()
}

export default {
  getCookie,
  checkLogin,
  login,
  lookUp,
  logout
}
