<template>
  <b-modal
    v-bind="$attrs"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    centered
    v-on="$listeners"
  >
    <div
      class="text-center"
      style="color: gray"
    >
      <img
        class="mb-2"
        src="@/assets/nova_versao_obrigatoria.svg"
        style="max-width: 120px"
      >
      <p class="mb-0 mt-10">
        Para continuar acessando o portal, por favor atualize a versão deste aplicativo.
      </p>
      <b-link
        @click="$emit('open')"
      >
        <img
          class=""
          src="@/assets/google_play_badge.png"
          style="max-width: 200px"
        >
      </b-link>
      <br>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue/esm/components/modal'

export default {
  model: BModal.options.model
}
</script>
